.tag-line {
  margin-top: 1rem;
  margin-bottom: 1rem;

  .tag-item {
    display: inline-block;
    cursor: pointer;

    &:not(:first-child) {
      margin-left: 0.75rem;
    }
  }
}
