@import "../../../assets/styles/variables";

.section-popular {
    position: relative;
    display: flex;
    flex-direction: column;

    .popular-top {
        position: relative;
        height: 220px;
        display: flex;
        align-items: center;
    }

    .popular-top h2 {
        font-size: 70px;
        line-height: 50px;
        font-weight: 700;
        color: #0C0F11;
    }

    .line-popular {
        height: 7px;
        width: 300px;
        position: relative;
        background: #0C0F11;
        top: 60px;
    }

    .popular-bottom {
        display: flex;
        position: relative;
        padding-top: 20px;
        height: 100%;
    }

    .popular-right h3 {
        font-weight: 700;
    }

    .popular-right h4 {
        color: #575F68;
    }

    .popular-price {
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 10px;
    }

    .card-popular {
        height: 489px;
        background-color: #7A8795;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
    }

    .card-popular .pin {
        position: relative;
        top: 10px;
    }

    .btns-group-popular {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: 120px;
        margin-top: 20px;
    }

    .btns-group-popular .btn-cube {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        background: white;
        border-radius: 4px;
        color: #000;
        font-weight: 400;
        font-size: 14px;
        cursor: pointer;
    }

    .popular-paragraph p {
        margin-top: 20px;
    }
}

@media screen and (max-width: $sm) {
    .section-popular [class*="gl-col-"] {
        max-width: 100% !important;
    }
}
