.mediumcard{
    position: relative;
    height: 180px;
    width: 279px;
    background-color: #f3f5f7;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}