@import "../../../../assets/styles/variables";
.box-r-side {
    background: #fff;
    width: 600px;
    height: 75vh;
    overflow-y: scroll;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    margin-left: 80px;
    padding: 50px;
    position: relative;

    @media screen and (max-width: $md) {
        width: 350px;
    }

    .img-r-side {
        width: 100%;
        height: 382px;
        margin-bottom: 20px;
    }

    .row-certif-r-side {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        height: 52px;
        margin-bottom: 40px;

        .certif-bool {
            position: relative;
            width: 230px;
            height: 40px;
            display: flex;

            h5 {
                position: relative;
                font-size: 16px;
                color: #7a8795;
            }

            .code-s {
                position: absolute;
                left: 58px;
                top: 20px;
                font-size: 16px;
                line-height: 30px;
            }

            .qr-code {
                position: relative;
                left: 0;
                width: 38px;
                height: 38px;
                margin-right: 20px;
                border-radius: 4px;
            }
        }

        .invalid {
            filter: blur(5px);
        }

        .disabled {
            background: #d6d8da;
            cursor: initial;
        }
    }


    .url-box-img, .status-box-img {
        margin-bottom: 40px;

        h4 {
            color: #7A8795;
            font-size: 18px;
            letter-spacing: 0.5px;
        }

        p {
            font-size: 16px;
            color: #0C0F11;
        }
    }
}
