@import "../../../../assets/styles/variables";

.avatar-setting {
  width: 100%;
  height: 100%;
  background: $wirebg;
  display: flex;
  justify-content: flex-end;
  background-size: cover;
  background-position: 50%;

  .field-group {
    width: 100%;
    height: 100%;

    .vertical-content-centered {
      width: 100%;
      height: 100%;
      align-items: center;
    }
  }

  .btn-action {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0.25rem;

    .btn-change-avatar label {
      margin-bottom: 0;
      cursor: pointer;
    }

    .button:not(:first-child) {
      margin-left: 0.5rem;
    }
  }
}
