@import "../../../../assets/styles/variables";

.regular-grid-content {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  grid-auto-rows: minmax(400px, min-content);
  grid-auto-flow: dense;

  .grid-item {
    position: relative;
    background: no-repeat 50%;
    background-size: cover;

    &.double {
      grid-column: span 2;
    }
  }
}

@media screen and (max-width: $md) {
  .regular-grid-content {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: $sm) {
  .regular-grid-content {
    grid-template-columns: 1fr;

    .grid-item {
      grid-row: 1 span !important;
      grid-column: 1 span !important;
    }
  }
}
