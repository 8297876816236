.edit{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    color: #000;
    background: #f3f5f7;
    border-radius: 4px;
    font-size: 14px;
    white-space: nowrap;
}