.section-innovative{
    position: relative;
    height: 523px;
    margin-bottom: 10em;
    background: #F3F5F7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



.box-innovative{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.innovative-content{
    font-size: 50px;
    font-weight: 700;
    width: 900px;
    display: flex;
    text-align: center;
    margin-bottom: 20px;
}

.box-innovative h4{
    font-size: 16px;
}