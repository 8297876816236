@import "../../../assets/styles/variables";

.no-image-grid {
  position: relative;
  margin: auto;
  min-height: 100%;
  width: 100%;
 

  .grids-content {
    display: flex;
    flex-flow: row wrap;
    margin: auto;
    width: 100%;

    .photographer-card {
      margin-left: 2rem;
      margin-bottom: 2rem;
    }
  }

  .center-content {
    min-height: 100%;
    display: flex;
  }
}

.nodisplay{
  position: absolute;
  left: 35%;
  top: 20%;
  white-space: nowrap;
}