@import "../../../../../assets/styles/variables";

.agency-card {
    background: $wirebg;
    width: 100%;
    padding: 1rem 2rem;
    &:not(:first-child) {
        margin-top: 1rem;
    }
    .agency-data {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h5 {
            position: relative;
            z-index: 1;
            margin: 0;
            &::after {
                content: '';
                width: 100%;
                height: 4px;
                background: $secondary-color;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: -1;
            }
        }
    }
}
