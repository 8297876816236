@import '../../../../assets/styles/variables';

.left-links-up{
    position: relative;
    top: 30px;
    height: 100%;
    width: 300px;
    padding: 40px;
    min-width: 16%;

    .row-left-links{
        display: flex;
        list-style-type: none;
        font-size: 18px;
        padding: 10px;
        color: $fontgrey;
        cursor: pointer;

        .tick{
            position: relative;
        }
    }

    h3{
        font-size: 22px;
        color: #575F68;
        margin-bottom: 15px;
    }
    
    h4 {
        font-size: 18px;
        color: #575F68;
        padding: 10px;
        &:hover, &.active{
            font-weight: 700;
        }
    }
}
