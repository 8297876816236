@import "../../../../assets/styles/variables";

.autocomplete-root {
    position: relative;
    .autocomplete-dropdown-container {
        position: absolute;
        width: 100%;
        .suggestion-item {
            position: relative;
            font-size: 16px;
            padding: 10px;
            width: 100%;
            cursor: pointer;
            background-color: $wirebg;

            &:hover,
            &.active {
                background: darken($secondary-color, 5);
            }

            &:not(:last-child) {
                border-bottom: 1px solid darken(#f3f5f7, 10);
            }
        }
    }
}
