@import "../../../assets/styles/variables";

.registration-form {
    min-height: 100%;
    width: 100%;

    .authent-error {
        color: $in-error;
        //position: absolute;
    }
}
