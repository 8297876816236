.mywholepage {
  position: relative;
  width: 1700px !important;
  height: 2000px !important;
  border: 1px solid black;
}
.leftpageside1 {
  position: relative;
  left: 100px;
  top: 180px;
  bottom: 150px;
  width: 300px;
  height: 100px;
}
.leftpageside67 {
  position: relative;
  left: 160px;

  bottom: 20px;
  width: 100%;
  height: 180%;
}
.purchased-grid-itemj {
  position: relative;
  bottom: 140px;
  left: 200px;

  width: 80%;
  height: 80%;
}

.entouragediv {
  position: relative;
  left: 270px;
  top: 75px;
  width: 600px;
  height: 40px;
  border: 1px solid black;
}
.entouragediv2 {
  position: relative;
  left: 270px;
  top: 75px;
  width: 90px;
  height: 117px;
  border: 1px solid black;
}
.entouragediv3 {
  position: relative;
  left: 360px;
  bottom: 42px;
  width: 510px;
  height: 117px;
  border: 1px solid black;
}
.rightpageside1 {
  position: relative;
  left: 870px;

  width: 120px;
  height: 100px;
}
.leftpageside2 {
  position: relative;
  font-size: medium;
  left: 270px;
  bottom: 150px;
  width: 610px;
  height: 280px;
  border: 1px solid black;
}
.rightpageside2 {
  position: relative;
  left: 920px;

  bottom: 305px;
  width: 480px;
  height: 150px;
  border: 1px solid black;
}
.thetwosides {
  position: relative;
  left: 270px;
  bottom: 228px;
  width: 1130px;
  height: 255px;
  border: 1px solid black;
}
.leftsideimagej {
  position: relative;
  left: 70px;

  width: 300px;
  height: 300px;
}
.rightpageside3 {
  position: relative;
  left: 635px;
  bottom: 745px;
  width: 770px;
  height: 390px;
  border: 1px solid black;
}
.descriptimageon {
  position: relative;
  left: 270px;
  overflow-wrap: break-word;
  bottom: 150px;
  width: 310px;
  height: 200px;
}
.descriptmyimageon {
  position: relative;
  bottom: 380px;
  left: 270px;
  color: #9ba5b1;
  width: 110px;
  height: 100px;
}
.leftpageside3 {
  position: relative;
  left: 130px;
  bottom: 720px;
  width: 450px;
  height: 120px;
}
.rightpageside4 {
  position: relative;
  left: 600px;
  bottom: 660px;
  width: 350px;
  height: 120px;
}
.halfleftside {
  position: relative;
  width: 30%;
  height: 100%;
  overflow-wrap: break-word;
  border: 1px solid black;
}

.thismylogo {
  position: relative;
  top: 90px;
  left: 100px;
  width: 100%;
  height: 100%;
}
.putthismylogo {
  position: relative;
  top: 70px;
  left: 30px;
  width: 50%;
  height: 100%;
}
.thismyqrcode {
  position: relative;

  left: 170px;
  width: 200px;
  height: 150px;
}
.blockchaindiv {
  position: relative;
  color: #9ba5b1;

  left: 210px;
  top: 40px;
}
.confirmedverificationdiv {
  color: #9ba5b1;
  position: relative;
}
.thismylogo2 {
  position: relative;
  left: 400px;
  bottom: 275px;
  width: 100%;
  height: 100%;
}
.thisfirstnamediv2 {
  position: relative;
  bottom: 155px;
  left: 905px;
  font-size: 35px;
  font-weight: 700;
}
.halfleftside h5 {
  color: #9ba5b1;
}
.halfrightside {
  position: relative;
  overflow-wrap: break-word;
  bottom: 277px;
  left: 195px;
  width: 405px;
  
}
.thisfirstnamediv {
  position: relative;
  bottom: 567px;
  left: 1300px;
  color: #9ba5b1;
  width: 435px;
}
.halfrightside h5 {
  overflow-wrap: break-word;
}

.halfrightside2 {
  position: relative;

  bottom: 152px;
  width: 400px;

  left: 110px;
}
.halfrightside2 h5 {
  overflow-wrap: break-word;
}
.halfrightside3 {
  position: relative;
  overflow-wrap: break-word;
  bottom: 250px;
  left: 365px;
}
.halfrightside3 h5 {
  overflow-wrap: break-word;
}
.halfrightside4 {
  position: relative;
  width: 600px;
  bottom: 388px;
  left: 245px;
}
.halfrightside4 h5 {
  overflow-wrap: break-word;
}
.halfleftsideleft {
  position: relative;
  width: 20%;
  height: 100%;
  border: 1px solid black;
}
.halfleftsideleft h5 {
  color: #9ba5b1;
}
