@import "../../../../assets/styles/variables";

.admin-stats-section {
  padding-top: 100px;
  margin: 0 auto 5rem;

  .admin-stats-content {
    .admin-stats-bloc {
      background: lightgray;
      display: flex;
      justify-content: space-between;
      padding: 1rem;

      &:not(:first-child) {
        margin-left: 1rem;
      }

      .admin-stats-data {
        .stats-counter,
        .stats-label {
          margin: 0;
        }

        .stats-counter {
          color: blue;
        }
      }
    }
  }
}

@media screen and (max-width: $sm) {
  .admin-stats-section [class*="gl-col-"] {
    max-width: 100% !important;
  }
}
