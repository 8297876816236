@import "../../../../assets/styles/variables";

.photographer-card {
  &:not(:first-child) {
    margin-left: 2rem;
  }

  .card-content-name {
    color: #575F68;
    padding: 5px 0 5px 0;
  }

  .card-content-location {
    padding: 0 1rem;
    display: flex;
    align-items: center;
  }

  .btn-vdetails {
    position: absolute;
    left: 50%;
    top: 50%;
    opacity: 0;
    transform: translateY(100%) translateX(-50%);
    transition: all 0.2s ease-in-out;
    text-align: center;
    color: #fff;
    background: rgba(#000, 0.66);
    padding: 0.5rem 2rem;
    display: flex;
    align-items: center;
    width: auto;
    justify-content: center;
    min-width: 150px;
  }

  .card-req:hover .btn-vdetails {
    opacity: 1;
    transform: translateY(-50%) translateX(-50%);
  }

  .card-req:hover .btn-accept {
    opacity: 1;
  }

  .card-req:hover .btn-decline {
    opacity: 1;
  }

  .btn-accept {
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0;
  }

  .btn-decline {
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
  }

  .profile-image {
    background-size: cover;
    background-position: 50%;
    position: relative;

    .cloudimage-background-content {
      height: 100%;
    }
  }
}

