@import "../../../../assets/styles/variables";

.mobile-certif {
    display: none;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.4);
  z-index: 2;

  @media screen and (max-width: $md) {
      display: flex;
  }

  .certif-pos {
    position: absolute;
    top: 10%;
    right: 20%;
  }

  .back-chevron {
    position: absolute;
    width: 50px;
    height: 50px;
    right: 20px;
    z-index: 3;
    top: 70px;
    cursor: pointer;
  }
}
