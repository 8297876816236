.authent-layout {
    display: flex;
    flex-flow: row nowrap;
    min-height: 100vh;
    .authent-form-container {
        min-height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        .authent-content {
            max-width: 100%;
            width: 434px;
            margin-top: 17%;
        }
    }
}
