@import "../../../../assets/styles/variables";

.photographers-card {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 1px solid transparent;
  &:hover {
    border-color: $secondary-color;
    .content-photographer {
      &::before {
        opacity: 1;
      }
      .more-plus {
        transform: translateY(0);
      }
    }
  }
  .image-photo {
    position: relative;
    background: no-repeat #f3f5f7 50%;
    background-size: cover;
    min-height: 272px;
    padding: 0 !important;
  }
  .content-photographer {
    overflow: hidden;
    padding: 10px;
    position: relative;
    height: 100%;
    &::before {
      content: '';
      transition: .25s all ease-in-out;
      opacity: 0;
      height: 100px;
      width: 400px;
      transform: translateX(57%) rotateZ(-45deg);
      background: $secondary-color;
      position: absolute;
      bottom: 0;
      right: 0;
    }
    .content-localisation {
      display: flex;
      align-items: center;
      h4 {
        position: relative;
        font-size: 18px;
        margin-bottom: 0;
        margin-left: .5rem;
      }
      .icon-localisation{
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F3F5F7;
        border-radius: 50%;
      }
    }
    .more-plus {
      position: absolute;
      bottom: 10px;
      right: 10px;
      display: flex;
      transform: translateY(150%);
      transition: .25s all ease-in-out;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;

      h4 {
        margin-bottom: 0;
      }
      .icon-plus{
        margin-left: 5px;
        width: 30px;
        height: 30px;
      }

    }
  }
}
