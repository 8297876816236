.section-header {
    padding-top: 100px;

    .header-content {
        max-width: calc(2 / 3 * 100%);

        .description {
            font-size: 30px;
            white-space: pre-line;
        }
    }
}
