.section-sameseries {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 381px;
    width: 1419px;
    left: 100px;
    margin-bottom: 5em;
}


.section-sameseries .box-series-top{
    height: 120px;
    width: 100%;
    display: flex;
    position: relative;
}

.section-sameseries h2{
    position: relative;
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
}

.section-sameseries .line-series{
    position: relative;
    height: 7px;
    width: 209px;
    background: #0C0F11;
    top: 80px;
    left: 8px;
}

.section-sameseries .box-arrows {
    position: absolute;
    display: flex;
    right: 100px;
    width: 92px;
    height: 45px;
    top: 60px;
}

.section-sameseries .box-left {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    background: #F3F5F7;
    color: #7A8795;
}
.section-sameseries .box-right {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    background: #7A8795;
    color: #F3F5F7;
    left: 2px;
}

.section-sameseries .grid-series {
    height: 180px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 13px;
}
