.keywords-form {
    .kw-group {
        margin-bottom: 1rem;

        .rm-keyword-icon {
            cursor: pointer;
            align-self: center;
        }
    }
}
