.notif{
    width: 15px;
    height: 14px;
    background: #7A8795;
    color: #fff;
    position: relative;
    font-size: 10px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}