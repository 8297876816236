$colors:(
    font: #7A8795,
    blackfont: #0C0F11,
    darkgrey: #9BA5B1,
    white:#F3F5F7
);

@function color($colorName){
    @return map-get($colors, $colorName)
};


.settingpass{
    color: color(blackfont);
    width: 38%;
    height: 450px;

    .title-set{
        font-size: 30px;
        margin-bottom: 30px;
        white-space: nowrap;
    }

    .btns-wrapper{
        width: 100%;
        height: 50px;
        display: flex;
    }
}
