.section-micro-payment {
  height: 800px;
  display: flex;
}
.container-micro-left {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.container-micro-right {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.micro-payment-grey {
  height: 550px;
  background-color: #f3f5f7;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content-purchase {
  display: flex;
  position: relative;
  border: 1px solid #9ba5b1;
  width: 656px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-purchase h2 {
  font-weight: 700;
  font-size: 60px;
}
.btns-micro {
  display: flex;
  height: 45px;
  position: relative;
  top: 10px;
  align-items: center;
}
.btn-micro1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 200px;
  cursor: pointer;
  background-color: #0c0f11;
  border-radius: 4px;
  position: relative;
  left: 100px;
  color: #fff;
  margin-right: 24px;
}
.btn-micro2 {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 200px;
  left: 100px;
  color: #fff;
  background-color: #7a8795;
  border-radius: 4px;
  position: relative;
}
.line-purchase {
  height: 7px;
  width: 400px;
  right: 100px;
  top: 120px;
  background-color: #0c0f11;
  position: relative;
}
.paragraph-purchase {
  width: 600px;
  height: 440px;
  font-size: 25px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  color: #0c0f11;
  position: relative;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  top: 150px;
  line-height: 40px;
}

.icon-encrypt{
  position: relative;
  width: 80px;
  height: 80px;
  left: 90px;
}