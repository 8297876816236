.instructions-form {
    .si-group {
        margin-bottom: 1rem;

        .rm-instruction-icon {
            cursor: pointer;
            align-self: center;
        }
    }
}
