.box-list {
  height: 120px;
  width: 303px;

  position: relative;
  font-weight: 100px;
  font-size: 40px;
  display: flex;
  flex-direction: column;
}
.box-liste {
  height: 120px;
  width: 303px;
  color: #575f68;

  position: relative;
  font-weight: 100px;
  font-size: 30px;
  display: flex;
  flex-direction: column;
}
.box-create {
  font-size: 25px;
  top: 55px;
  display: flex;
  left: 200px;
  flex-direction: column;
  width: 20%;

  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
}
.label-t {
  color: #0c0f11;
  position: relative;
  display: flex;
  font-size: 18px;
}
.btncl {
  position: relative;
  display: flex;
  top: 20px;
  background-color: #0c0f11;
}
.label-term {
  position: relative;
  display: flex;
  font-size: 30px;
  right: 30px;

  color: #575f68;
}
.box-indent h3 {
  position: relative;
  display: flex;
  right: 70px;
  flex-direction: column;
}
.box-cont {
  height: 700px;
  display: flex;
  padding: 100px;
}
.box-cont h3 {
  position: relative;
  color: #575f68;
  font-size: 20px;
}
.box-cont h4 {
  color: #0c0f11;
  font-size: 20px;
}
.type-im h3 {
  position: relative;
  right: 110px;
  display: flex;
}
.type-i h3 {
  position: relative;
  right: 120px;
  display: flex;
}
.type-imo h3 {
  position: relative;
  right: 75px;
  display: flex;
}
.type-i h4 {
  position: relative;
  left: 19px;
  display: flex;
}
.type-im h4 {
  position: relative;
  left: 30px;
  display: flex;
}
.type-imp h3 {
  position: relative;
  right: 95px;
  display: flex;
}
.type-imp h4 {
  position: relative;
  left: 10px;
  display: flex;
}
.type-ime h3 {
  position: relative;
  right: 142px;
  display: flex;
}

.box-order {
  display: flex;
  background-color: #f3f5f7;
  background-size: cover;
  margin-bottom: 30px;
  width: 100%;
  height: 300px;
}

.box-l {
  position: relative;
  width: 50%;
  display: flex;
  left: 0px;
  flex-direction: column;
}
.box-r {
  left: 20px;
  flex-direction: column;
  width: 15%;
  position: relative;
  display: flex;
  flex-direction: column;
}
