.cart-container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    z-index: 999;
    .cart-content {
        position: relative;
        width: 100%;
        height: 100%;
        & > * {
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
            background: none;
            overflow: visible !important;
            #cart-modal {
                position: absolute;
                margin-top: 65px;
                right: 0;
                box-shadow: 0 0 20px -10px #000;
                #close-cart-icon {
                    width: 2.5rem;
                    height: 2.5rem;
                }
            }
        }
    }
}
