@import "../../../assets/styles/variables";

.section-statistics{
    position: relative;
    padding-top: 100px;
}


@media screen and (max-width: $sm) {
    .section-statistics [class*="gl-col-"] {
        max-width: 100% !important;
    }
}
