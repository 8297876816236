@import '../../../../assets/styles/variables';

.addtomood {
  display: flex;
  position: absolute;
  top: 60px;
  right: 0;
  width: 272px;
  background: #fff;
  color: $primary-color;
  border-radius: 4px;
  flex-direction: column;
  z-index: 40;
  white-space: nowrap;

  .moodtop {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      margin: 0;
      padding: 0.5rem 0;
    }
  }

  .moodbox {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 200px;
    overflow: auto;

    .moodlinks {
      align-items: center;
      cursor: pointer;
      padding: 0 1rem;

      &:hover {
        background: #f3f5f7;
      }

      h5 {
        font-size: 24px !important;
      }
    }
  }

  .moodlinks-create {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 65px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: $secondary-color;
  }

  .add-view-success .button-placeholder {
    position: absolute;
    left: 0;
    top: 0;
    width: 240px;
    .button-placeholder-content {
      width: 0;
      overflow: hidden;
      transition: all 4s linear;
      &.displayed {
        width: 100%;
      }
    }
  }
}
