$primary-color: #0c0f11;
$secondary-color: #ffe942;
$wirebg: #f3f5f7;
$black: #0C0F11;
$disabled-grey: #9ba5b1;
$fontgrey: #575F68;
$in-error: #f56959;

$sm: 767px;
$md: 1199px;
$lg: 1400px;
