.littlecard{
    position: relative;
    height: 180px;
    width: 128px;
    background-color: #f3f5f7;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}