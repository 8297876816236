@import '../../../../assets/styles/variables';

.box-cont-head {
    padding-top: 100px;

    .moodboard-edit-form {
        display: flex;
        justify-content: space-between;

        .form-container {
            width: 50%;

            .field-group {
                &:not(:first-child) {
                    margin-top: 1rem;
                }

                #moodboard-description {
                    min-height: 200px;
                }

                &.keep-private {
                    background: $wirebg;
                    padding: 0.75rem;

                    label {
                        margin-left: 0.5rem;
                    }
                }
            }
        }
    }
}


.moodboard-add-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
