.personal-infos-form {
    .field-group:not(:first-child) {
        margin-top: 1rem;
    }
    .btn-container {
        display: flex;
        justify-content: space-between;
        .button {
            text-align: center;
            min-width: 100px;
        }
    }
}
