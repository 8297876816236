.box-series-edit{

    display: flex;
    flex-direction: column;

    h3{
        margin-bottom: 30px;
    }

    .or-box-line{
        position: relative;
        margin: 25px 0;
        display: flex;
        align-items: center;
        color: #9BA5B1;

        .line-or{
            position: relative;
            top: 4px;
            background: #9BA5B1;
            width: 250px ;
            height: 1px;
            margin-left: 12px;
        }
    }
}