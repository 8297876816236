@import "../../../../../assets/styles/variables";

.get-prepare-left {

    .tags-container {
        margin-top: 1rem;

        .tags {
            display: flex;
            overflow-y: auto;

            .btn-tag {
                width: max-content;

                &:not(:first-child) {
                    margin-left: 0.5rem;
                }
            }
        }
    }

    .instructions {
        margin-top: 2rem;

        .special-instruction {
            background: $wirebg;
            padding: 1rem;

            .instruction-tag {
                display: flex;
                align-items: center;
                white-space: pre-line;

                &:before {
                    content: '';
                    height: 1rem;
                    width: 1rem;
                    min-height: 1rem;
                    min-width: 1rem;
                    border-radius: 100%;
                    border: solid 2px $secondary-color;
                    margin-right: 0.5rem;
                }

                &:not(:last-child) {
                    margin-bottom: 0.5rem;
                }
            }
        }
    }
}
