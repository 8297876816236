@import "../../../../assets/styles/variables";

.photography-card {
    width: 300px;
    height: 200px;
    background-size: cover;
    background-position: 50%;
    margin: 0.5rem;
    &.review-image {
        width: 600px;
        height: 400px;
    }
    .cloudimage-background-content {
        height: 100%;
    }
    .card-content {
        height: 100%;
        .photography-view-details {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, 200%);
            transition: all .2s ease-in-out;
            opacity: 0;
            overflow: hidden;
            background: rgba(#000, 0.66);
            padding: 0.5rem 2rem;
            display: flex;
            align-items: center;
            width: auto;
            color: #fff;
        }
        .photography-element-container {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            height: 100%;
            padding: 0.5rem;
            .photography-card-credits {
                p {
                    margin: 0;
                    &.credit-label {
                        color: #fff;
                    }
                    &.credit-value {
                        color: $secondary-color;
                    }
                }
            }
            .photography-card-actions {
                .button {
                    min-width: 100px;
                    text-align: center;
                    opacity: 0.90;
                    &:not(:first-child) {
                        margin-top: 0.25rem;
                    }
                }
            }
        }
        &:hover {
            .photography-view-details {
                transform: translate(-50%, -133%);
                opacity: 1;
            }
        }
    }
}
