@import "../../assets/styles/variables";

.not-found-section {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .section-header {
        flex-direction: column;

        h1 {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 7.5%;
                height: 1.5rem;
                z-index: -1;
                width: 100%;
                background: $secondary-color;
            }
        }
    }
}
