@import "../../../assets/styles/variables";

.login-form-container {
    .field-group:not(:first-child) {
        margin-top: 1rem;
    }
    .login-form {
        height: max-content;
        .field-group {
            display: flex;
            flex-flow: column nowrap;
            position: relative;
            .field-error-container {
                position: relative;
                .field-error-message {
                    position: absolute;
                    color: #ff0000;
                }
            }
            .forgot-password-anchor {
                margin-top: 0.5rem;
                font-size: 0.75rem;
                text-decoration: underline;
                cursor: pointer;
            }
        }
        .authent-error {
            color: $in-error;
            // position: absolute;
        }
    }
}
