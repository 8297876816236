.purchased-grid-item {
  width: 133px;
  height: 75px;
  background-size: cover;
  background-position: 50%;
  float: left;
  cursor: pointer;

  &:not(:first-child) {
    margin-left: 1rem;
  }

  &:hover {
    .cloudimage-background-content {
      opacity: 1;
      backdrop-filter: blur(5px);
    }
  }

  .cloudimage-background-content {
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 100ms ease-in-out;

    .grid-item-content {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg,
              rgba(0, 0, 0, 0.5),
              rgba(0, 0, 0, 0.2),
              transparent,
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0.5)
      );
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
