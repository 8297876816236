.btn-tag1{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 74px;
    height: 36px;
    color: #575F68;
    background: #f3f5f7;
    border-radius: 16.5px;
    font-size: 14px;
    white-space: nowrap;
}