@import "../../../assets/styles/variables";

.section-ph-edit-uploaded {
  display: flex;
  min-height: 100vh;
  position: relative;
  top: 20px;

  .cont-ph-editup-l{
    width: 250px;
  }

  .cont-ph-editup-r {
    position: fixed;
    right: 3%;
    z-index: 1;

    @media screen and (max-width: $md) {
      display: none;
    }
  }
}

.cont-ph-editup-m {
  position: relative;
  min-height: 200px;
  width: 33%;
  padding: 40px;
  @media screen and (max-width: $md) {
      width: 40%;
      padding: 0;
  }
  

  .save-btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 80px;
    width: 80px;
    height: 36px;
    background: #ffe942;
    border-radius: 4px;
    cursor: pointer;
    opacity: 0.8;
    transition: all 0.2s ease;

    &:hover {
      opacity: 1;
    }

    &:active {
      background: #ffe942;
    }
  }
}

.mobile-r-certif {
  display: none;
  position: relative;
  min-width: 200px;
  min-height: 100%;

  @media screen and (max-width: $md) {
    display: flex;
    top: -100px;
    height: 100px !important;
    right: 10%;
    .btn-certif-mob {
      position: absolute;
      top: 85px;
      right: 150px;
      width: 100px;
      height: 50px;
      cursor: pointer;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }
}
