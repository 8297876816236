@import "../../../../assets/styles/variables";

.btn-tag {
  border-radius: 1rem;
  color: $fontgrey;
  cursor: pointer;
  padding: 0.25rem 1rem;
  width: max-content;
  min-width: max-content;
  display: flex;
  align-items: center;

  &:not(.secondary-background) {
    background: $wirebg;
  }

  &:not(:first-child) {
    margin-left: 0.5rem;
  }
}

.arrow-tag {
  margin-left: 1.5rem;
  cursor: pointer;
}
