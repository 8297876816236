.btn-profile1{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #9BA5B1;
    width: 129px;
    height: 50px;
    border-radius: 4px;
    color: #fff;
    position: relative;
}