.btn-back1{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 200px;
    height: 50px;
    background: #F3F5F7;
    font-size: 14px;
    white-space: nowrap;
    color: #0C0F11;
    padding-right: 12px;
    cursor: pointer;
}

.arrow-back{
    font-size: 20px;
    margin-right: 5px;
    color: #575F68;
}