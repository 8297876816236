.close-overview {
    position: absolute;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 20px;
}

.close-ov-icon {
    color: #FFE942;
    width: 27px;
    height: 25px;
    margin-right: 5px;
    cursor: pointer;
}

.overview-image-content {
    height: 100% !important;
    max-width: 80%;
    max-height: 80%;
    padding-bottom: 0 !important;
    background: none !important;
}

.overview-image-content > div,
.overview-image-content > img {
    width: auto !important;
    height: 100% !important;
    margin: auto;
}

.overview-image-content > div {
    left: 50% !important;
    transform: translateZ(0) translateX(-50%) !important;
}

.overview-image-content > img {
    position: initial !important;
}
