@import "./variables";

.button {
    background: $primary-color;
    border-radius: 5px;
    padding: .25rem .75rem;
    height: max-content;
    width: max-content;
    border: none;
    cursor: pointer;
    text-align: center;
    font-size: 16px;

    display: flex;
    justify-content: center;
    align-items: center;

    &.primary {
        background: $primary-color;
        color: $secondary-color;
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;

        &:hover:not(.disabled) {
            background: $secondary-color;
            color: $primary-color;
        }
    }

    &.secondary {
        background: $secondary-color;
        color: $primary-color;

        &:hover:not(.disabled) {
            background: $primary-color;
            color: #fff;
        }
    }

    &.tertiary {
        background: $wirebg;

        &.action-btn {
            width: 100% !important;
            text-align: center;
            padding: 0.75rem 0;
            margin-top: 1rem;
            background: #fff;
            border: solid 1px;
        }

        &:hover:not(.disabled) {
            background: $secondary-color;
            border-color: $secondary-color;
        }
    }

    &.wired {
        background: #fff;
        border: solid 1px $primary-color;
        color: $primary-color;
    }

    &.disabled {
        background: $disabled-grey;
        cursor: initial;
    }

    &.btn-default-height {
        height: 36px;
    }

    &.btn-default-width {
        min-width: 127px;
    }

    &.btn-full-width {
        width: 100%;
    }

    &.color-white {
        color: #fff;
    }
}

.tag {
    background: #000;
    margin-left: .5rem;
    border-radius: 5px;
    padding: .25rem .5rem;
    height: max-content;
    display: flex;
    align-items: center;

    &.primary {
        background: $primary-color;
    }

    &.secondary {
        background: $secondary-color;
    }

    &.tertiary {
        background: $wirebg;
    }

    &.released {
        color: blue;
        background: none;
        border: 1px solid blue;
        width: min-content;
    }
}
