@import "../../assets/styles/variables";

.admin-reviews-main {
    padding-top: 100px;
    min-height: 66vh;
    display: flex;

    .admin-reviews-content {
        position: relative;
        width: 100%;
          @media screen and (max-width: $md) {
              width: 70%;
              left: 10%;
          }
          @media screen and (max-width: $sm) {
              width: 60%;
          }
    }
}
