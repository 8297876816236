@import "../../../../assets/styles/variables";

.input-reg[type="text"],
.input-reg[type="number"],
.input-reg[type="select"]{
  padding: 10px;
  background: #f3f5f7;
  width: 100%;
  height: 40px;
  border: none;
}

.input-reg[type="textarea"],
textarea.input-reg {
  background: #f3f5f7;
  width: 100%;
  height: 220px;
  border: none;
}

.input-reg[type="submit"] {
  background: #ffe942;
  width: 100%;
  height: 50px;
  border-radius: 4px;
  margin-top: 32px;
  border: none;
}

.input-reg[type="password"] {
  background: #f3f5f7;
  padding: 10px;
  width: 100%;
  height: 40px;
  border: none;
}

.btn-next-reg {
  width: 100%;
  height: 50px;
  border-radius: 4px;
  background: #9ba5b1;
  margin-top: 32px;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  &.is-valid {
    cursor: pointer;
    background: $secondary-color;
  }
}


.input-reg[type="email"] {
  background: #f3f5f7;
  padding: 10px;
  width: 100%;
  height: 40px;
  border: none;
}

.input-reg[type="telephone"] {
  background: #f3f5f7;
  padding: 10px;
  width: 100%;
  height: 40px;
  border: none;
}

.input-reg[type="checkbox"] {
  background: #f3f5f7;
  width: 20px;
  height: 20px;
}
