.section-over-upload {
  height: 100vh;
  position: fixed;
  top: 0px;
  z-index: 400;
  width: 100%;
  background: rgba(155, 165, 177, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;

  .middle-ov-up {
    width: 236px;
    height: 250px;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    h3 {
      font-size: 60px;
      font-weight: 700;
    }
    .circle-ov-up {
      background: #f3f5f7;
      width: 168px;
      height: 168px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .image-upload-div {
        font-size: 50px;

        position: relative;
      }
    }
  }
}
