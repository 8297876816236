@import "../../../assets/styles/variables";

.simple-images-grid {
  justify-content: flex-start;

  .images-grid-item {
    height: 200px;
    margin: initial;
    margin-top: 0;
    margin-bottom: 0.5%;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
    margin-left: 0.25rem;

    &.image-selected::after {
      content: "Selected";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 20px;
      background: $secondary-color;
      text-align: center;
      font-size: 0.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
