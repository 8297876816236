.section-story-header {
  padding-top: 100px;

  .story-hedaer-content {
    max-width: calc(2 / 3 * 100%);

    .story-description {
      font-size: 30px;
      white-space: pre-line;
    }
  }
}
