.section-whatis{
    display: flex;
    position: relative;
    width: 100%;
    height: 95vh;
    color: black;
    margin-bottom: 30em;
}

.container-whatis-left{
    width: 50%;
    height: 100%;
    background: white;
}

.box-whatis-title{
    position: relative;
    top: 100px;
    left: 110px;
    width: 400px;
}

.box-whatis-title h2{
    font-size: 80px;
}

.box-whatis-paragraphs{
    position: relative;
    top: 200px;
    left: 100px;
    width: 500px;
    height: 800px;
}

.container-whatis-right{
    position: relative;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.whatis-rectangle1{
    position: absolute;
    width: 723px;
    height: 207px;
    background: #F3F5F7;
    top: 160px;
    right: 100px;
    background-size: cover;
    background-position: 0 -100px;
    background-repeat: no-repeat;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 4px;
}

.whatis-rectangle2{
    position: absolute;
    width: 247px;
    height: 338px;
    background: #F3F5F7;
    top: 160px;
    right: 100px;
    left: 89px;
    top: 260px;
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-size: cover;
    border: 1px solid black;


}

.whatis-rectangle2 h3{
    position: absolute;
    font-size: 16px;
    bottom: 0;
}


.whatis-rectangle3{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 247px;
    height: 338px;
    background: #fff;
    margin-top: 370px;
    right: 100px;
    left: 89px;
    top: 260px;
    padding: 10px;
    border: 1px solid grey;
    background-size: cover;

}


.whatis-rectangle4{
    position: absolute;
    width: 247px;
    height: 159px;
    background: #fff;
    margin-top: 270px;
    right: 100px;
    left: 359px;
    top: 260px;
    background-size: cover;
}


.rectangle4-top{
    position: absolute;
    display: flex;
    width: 100%;
    height: 50%;
    top: 0;
    background-color: rgb(206, 20, 51);
    background-blend-mode: darken;
    background-size: cover;
}
.rectangle4-bottom{
    position: absolute;
    display: flex;
    width: 100%;
    height: 50%;
    background: red;
    bottom: 0;
    
}

.rectangle4-bottom-left{
    width: 50%;
    border: 1px solid white;
    background-color: rgb(206, 20, 51);
    background-blend-mode: darken;
    background-size: cover;
}
.rectangle4-bottom-right{
    width: 50%;
    border: 1px solid white;
    background-color: rgb(153, 20, 206);
    background-size: cover;
    background-blend-mode: darken;
}


.whatis-rectangle4 h3{
    position: absolute;
    font-size: 18px;
    color: #575F68;
    top: 160px;
    font-weight: 700;
}
.whatis-rectangle4 h4{
    position: absolute;
    color: #0C0F11;
    top: 190px;
    font-size: 16px;
}


.box-bg-rec3{
    position: relative;
    display: flex;
    background-color: #F3F5F7;
    background-size: cover;
    background-position: 0 -70px;
    width: 100%;
    height: 205px;
    top: 0;
    left: 0;
}

.content-rec3-name{
    display: flex;
    position: relative;
    top: 10px;
    
}
.content-rec3-name h3{
    font-size: 24px;
}




.content-rec3-position{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    width: 170px;
    left:-20px;

}
.content-rec3-position h3{
    font-size: 14px;
}
.pos-circle{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #F3F5F7;
    top: -5px;
    left: -10px;
}



.whatis-rectangle5{
    position: absolute;
    width: 247px;
    height: 338px;
    background: #927a2a;
    right: 100px;
    left: 359px;
    top: 780px;
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-size: cover;
    border: 1px solid black;
    background-blend-mode: hard-light;


}

.whatis-rectangle5 h3{
    position: absolute;
    font-size: 16px;
    bottom: 0;
}

.whatis-p{
    position: relative;
    padding: 15px;
    line-height: 46px;
    color: #0C0F11;
}
