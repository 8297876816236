@import "./../../../../../assets/styles/variables";

.get-prepare-right {
  .btn-container {
    .button:not(:first-child) {
      margin-top: 0.5rem;
    }
  }

  .details {
    margin-top: 1rem;

    .credits-data {
      margin-top: 1rem;
    }

    .exifs-container {
      max-height: 200px;
      overflow-y: scroll;

      .inline-label {
        display: flex;
        justify-content: space-between;

        h4 {
          font-size: 0.9rem;
          color: $disabled-grey;
        }

      }
    }
  }
}
