.photographers-demands-menu {
    width: 16%;
    min-width: 16%;
    padding: 0 1.5rem;
    min-height: 100%;
    .menu-list {
        padding-left: 0;
        list-style-type: none;
        .menu-item {
            padding: 0.5rem 1rem;
            cursor: pointer;
            &.active,
            &:hover {
                background: #000;
                color: #fff;
            }
        }
    }
}
