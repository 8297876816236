@import "../../../../assets/styles/variables";

.custom-label-field {
  h6 {
    color: $fontgrey;
  }

  a {
    position: relative;
    z-index: 0;
    color: inherit;

    &:after {
      content: '';
      position: absolute;
      background: $secondary-color;
      height: 4px;
      width: 100%;
      bottom: 10%;
      left: 0;
      z-index: -1;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .detail-info-link {
    font-weight: 700;
  }
}
