@import "../../../../assets/styles/variables";

.container-stats {
  height: 450px;
  background: #fff;

  .container-stats h2 {
    font-weight: 700;
    margin-bottom: 20px;
  }

  .banderole-stats {
    height: 96px;
    display: flex;
    position: relative;
    padding: 20px;
    background: #F3F5F7;
    border-radius: 4px;
    margin-bottom: 32px;
  }

  .number-stats {
    position: absolute;
    left: 20px;
    top: 10px;
    font-weight: 550;
  }

  .title-stats {
    position: absolute;
    bottom: 20px;
    color: #7A8795;
    font-weight: 400;
  }

  .banderole-stats.v2 {
    background: #fff;
    display: flex;
    height: 96px;
    align-items: center;
  }

  .box-stats1, .box-stats2 {
    background: #f3f5f7;
    height: 96px;
    width: 48%;
    position: absolute;
    padding: 20px;
  }

  .box-stats1 {
    left: 0;
  }

  .box-stats2 {
    right: 0;
  }
}
