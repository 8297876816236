.small-banderole-card {
  cursor: pointer;
  .box-background {
    position: relative;
    height: 173px;
    background: #F3F5F7;
    background-size: cover;
    overflow: hidden;
    &.triple-box {
      width: 260px;
      .background-image {
        position: absolute;
        left: 0;
        width: 100%;
      }
    }
    &:not(.triple-box) {
      .background-image {
        height: 100% !important;
        width: auto !important;
        padding-bottom: 0 !important;
        > img {
          position: initial !important;
          height: 100%;
          width: auto !important;
        }
      }
    }
    .box-double {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: flex-end;
      padding-bottom: 2px;
      .box-double-item {
        width: 126px;
        height: 85px;
        background: #9ba5b1 50%;
        background-size: cover;
        border: 1px solid #fff;
      }
    }
  }
  .box-banderole-content {
    position: relative;
    color: #575f68;
    h3 {
      font-size: 18px;
    }
    h4 {
      font-size: 16px;
    }
  }
}
