.cardseries{
    position: relative;
    width: 263px;
    height: 360px;
    background-color: #f3f5f7;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.cardseries h4{
    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: 16px;
    color: #FFE942;
}