@import "../../../../assets/styles/variables";

.section-pagedetails {
  width: 100%;
  background: white;
  position: relative;
  margin-bottom: 10em;
  padding-top: 100px;

  .details-double {
    width: 100%;
    position: relative;
  }

  .btn-top-img {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    > * {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: $sm) {
  .section-pagedetails [class*="gl-col-"] {
    max-width: 100% !important;
  }
}
