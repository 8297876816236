.section-moodboard-header {
    padding-top: 100px;

    .moodboard-hedaer-content {
        max-width: calc(2 / 3 * 100%);

        .moodboard-description {
            font-size: 30px;
            white-space: pre-line;
        }
    }
}
