.section-acc-create {
  top: 60px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  background: white;
}
.container-acc-create {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}
.box-acc-create {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 600px;
  height: 40vh;

  flex-direction: column;
}
.content-acc-create {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: black;
  background-color: white;
  flex-direction: column;
}
.content-acc-create h2 {
  font-size: 50px;
  color: #0c0f11;
}
.validation-message {
  width: 750px;
  margin-top: 20px;
  font-size: 40px;
  color: #7a8795;

  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: center;
}
.icon-encrypt {
  width: 80px;
  margin-top: 20px;
  height: 80px;
}
