.section-about-bxcontent{
    position: relative;
    height: 523px;
    margin: 100px;
    margin-bottom: 10em;
}


.bxcontent-top{
    display: flex;
    height: 150px;
    background: white;
}

.line-bxcontent{
    position: relative;
    width: 469px;
    height: 7px;
    background: #0C0F11;
    margin-right: 19px;
    top: 100px;
}

.bxcontent-top h3{
    position: relative;
    font-weight: 700;
    font-size: 50px;
    line-height: 40px;
    color: #0C0F11;
    top: 70px;
}


.bxcontent-grid{
    width: 100%;
    min-height: 285px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 47px;
}


.box-bx-content{
    position: relative;
    width: 400px;
    height: 285px;
    background: #F3F5F7;
    background-size: cover;
}