.box-metadata-edit {
    min-height: 150vh;

    .edit-choice {
        display: flex;
        padding: 20px;

        h5 {
            padding: 0 30px;
            font-size: 20px;
            cursor: pointer;
            color: #575F68;

            &.active {
                font-weight: 700;
                border-bottom: 1px solid black;
            }
        }
    }

    .inputs-edit-up {
        .array-field-group {
            .array-container .photographer-field:not(:first-child) {
                .field-value {
                    margin-top: 1rem;
                }
            }
        }
    }
}
