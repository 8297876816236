@import "../../../../assets/styles/variables";

.footer-container {
  background: #0c0f11;
  color: #ffffff;
  margin-top: 20px;
  padding: 40px;

  .footer {
    width: 100%;
    color: #ffffff;
    margin: auto;
  }

  .footer-box {
    position: relative;
    height: 250px;
  }

  .footer-box img {
    position: relative;
    width: 56px;
    height: 56px;
    background: white;
    border-radius: 50%;
  }

  .footer-box h6 {
    padding-bottom: 32px;
  }

  .footer-box p {
    position: relative;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 0.5rem;
    cursor: pointer;
  }

  .copyright {
    position: relative;
    width: 100%;
    height: 16px;
    text-align: center;
    bottom: 16px;
    font-size: 14px;
    font-weight: 400;
  }
}

@media screen and (max-width: $md) {
  .footer-container .footer-box {
    text-align: center;
  }
}

@media screen and (max-width: $sm) {
  .footer-container .footer-box:first-child {
    max-height: 100px;
  }
}
