.input-reg.titlend{
    height: 200px;
}

.btns-row-up{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.box-titlend{
    display: flex;
    flex-direction: column;

    h3{
        margin-bottom: 30px;
    }
}
