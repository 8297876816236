.series-list-section {
    padding-top: 65px;
    .mb-list-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .create-btn {
            padding: 1rem;
            .plus-add {
                width: 30px;
                height: 30px;
                padding-bottom: 5px;
            }
        }
    }
}
