.purchase-card {
  width: 100%;
  max-width: 49%;
  margin-bottom: 1rem;

  .purchase-card-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .content-col {
      width: 49%;

      .detail-text {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &.highlight-text {
          font-weight: 600;
        }
      }
    }
  }
}
