@import "./variables";
@import "./fonts";

h1, h2, h3 {
  font-family: "GrifitoL Bold", BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
  font-weight: 700 !important;
  line-height: unset !important;
}

h4, h5, h6 {
  font-family: "PhantomSans Regular", BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
  font-weight: 400 !important;
}

h1,
.typo-size-primary {
  font-size: 90px !important;
}

h2,
.typo-size-secondary {
  font-size: 90px !important;
}

h3,
.typo-size-tertiary {
  font-size: 60px !important;
}

h4,
.typo-size-quaternary {
  font-size: 40px !important;
}

h5 {
  font-size: 22px !important;
}

h6 {
  font-size: 18px !important;
}

.typo-size-small {
  font-size: 16px !important;
}

.typo-size-smaller {
  font-size: 14px !important;
}

.typo-size-big {
  font-size: 120px !important;
}

.typo-size-bigger {
  font-size: 150px !important;
}

.phantom-bold {
  font-family: "PhantomSans Medium", BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: 700 !important;
}

.grifitol-bold {
  font-family: "GrifitoL Bold", BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: 700 !important;
}

@media screen and (max-width: $sm) {
  h1,
  .typo-size-primary {
    font-size: 50px !important;
  }

  h2,
  .typo-size-secondary {
    font-size: 50px !important;
  }

  h3,
  .typo-size-tertiary {
    font-size: 30px !important;
  }

  h4,
  .typo-size-quaternary {
    font-size: 25px !important;
  }

  h5 {
    font-size: 18px !important;
  }

  h6 {
    font-size: 16px !important;
  }

  .typo-size-small {
    font-size: 14px !important;
  }

  .typo-size-smaller {
    font-size: 12px !important;
  }

  .typo-size-big {
    font-size: 70px !important;
  }

  .typo-size-bigger {
    font-size: 90px !important;
  }
}
