.section-otherseries{
    position: relative;
    height: 1500px;
    margin-bottom: 5em;
    width: 1419px;
    left: 100px;
    display: flex;
    flex-direction: column;
}

.otherseries-top{
    display: flex;
    height: 100px;
    display: flex;
    align-items: center;
}

.otherseries-top h2{
    position: relative;
    font-size: 60px;
    font-weight: 700;
}

.line-otherseries{
    height: 7px;
    position: relative;
    width: 400px;
    background: #0C0F11;
    top: 15px;
    margin-right: 15px;
}

.grid-otherseries{
    display: grid;
    height: 1327px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 37px;
}

.card-explore1.other{
    width: 400px;
    height: 270px;
}
.card-explore2.other{
    width: 400px;
}

.card-explore1.other.left{
    bottom: 100px;
    right: 485px;
}

.otherseries-bottom{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 700px;
    height: 60px;
    width: 445px;
}

.btn-load{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 156px;
    height: 45px;
    border-radius: 4px;
    background: #000;
    color: white;
    margin-right: 15px;
    cursor: pointer;
}

.line-load{
    position: relative;
    height: 7px;
    width: 300px;
    background: #0C0F11;
    bottom: 18px;
}