@import "../../../../../assets/styles/variables";

.photographer-field {
    .field-label {
        margin-bottom: 0.5rem;
        margin-top: 1.5rem;
    }
    .field-value {
        background: $wirebg;
        margin: 0;
        height: 3rem;
        display: flex;
        align-items: center;
        padding: 0 1rem;
    }
}
