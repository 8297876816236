@font-face {
  font-family: "GrifitoL Bold";
  src: url("./../fonts/GrifitoL/GrifitoL-Bold.woff2") format("woff"),
  url("./../fonts/GrifitoL/GrifitoL-Bold.woff") format("woff");
}

@font-face {
  font-family: "PhantomSans Light";
  src: url("./../fonts/PhantomSansV0.8/TTF/PhantomSans0.8-Light.ttf") format("truetype"),
  url("./../fonts/PhantomSansV0.8/WOFF2/PhantomSans0.8-Light.woff2") format("woff2"),
  url("./../fonts/PhantomSansV0.8/WOFF/PhantomSans0.8-Light.woff") format("woff");
}

@font-face {
  font-family: "PhantomSans Medium";
  src: url("./../fonts/PhantomSansV0.8/TTF/PhantomSans0.8-Medium.ttf") format("truetype"),
  url("./../fonts/PhantomSansV0.8/WOFF2/PhantomSans0.8-Medium.woff2") format("woff2"),
  url("./../fonts/PhantomSansV0.8/WOFF/PhantomSans0.8-Medium.woff") format("woff");
}

@font-face {
  font-family: "PhantomSans Regular";
  src: url("./../fonts/PhantomSansV0.8/TTF/PhantomSans0.8-Regular.ttf") format("truetype"),
  url("./../fonts/PhantomSansV0.8/WOFF2/PhantomSans0.8-Regular.woff2") format("woff2"),
  url("./../fonts/PhantomSansV0.8/WOFF/PhantomSans0.8-Regular.woff") format("woff");
}
