.moodboard-credits {
    display: flex;
    flex-flow: row nowrap;
    padding: 0.5rem 0;

    > *:not(:first-child) {
        margin-left: 3rem;
    }

    .moodboard-author,
    .btns-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    .btns-container > * {
        padding-left: 1.75rem;
        padding-right: 1.75rem;
        height: 100%;
        display: flex;
        align-items: center;
    }
}
