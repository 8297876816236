.purchase-confirmation-container {
    min-height: calc(100vh - 100px);
    .padlock-illustration {
        max-height: 100px;
        max-width: 100px;
        height: 100px !important;
        width: 100px !important;
        padding-bottom: 0 !important;
        margin: 2rem auto;
    }
}
