@import "../../../assets/styles/variables";

.section-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  .title-container {
    display: flex;
  }

  .header-controls {
    display: flex;
    align-items: center;

    .view-all {
      text-decoration: underline;
      margin-right: 1rem;
    }

    &.sub {
      margin-top: 2%;
    }
  }

  &.tertiary-header {
    .title-container {
      .tertiary-title {
        font-weight: 700;
        font-size: 35px;
      }
    }
  }

  &.secondary-header {
    .title-container {
      display: flex;
      align-items: flex-end;

      .header-line {
        position: relative;
        width: 250px;
        height: 7px;
        background: #000;
        bottom: 25px;
      }

      &:not(.row-reverse) .secondary-title {
        margin-right: 10px;
      }

      &.row-reverse {
        flex-direction: row-reverse;

        .secondary-title {
          margin-left: 10px;
          text-align: right;
        }
      }

      &.no-line {
        .header-line {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: $md) {
  .header-line {
    display: none !important;
  }
}
