.photographer-details {
    a {
        text-decoration: none;
        color: inherit;

        &:hover {
            text-decoration: none;
            color: inherit;
        }

        .email-link,
        .ppage {
            width: 100% !important;
            text-align: center;
            padding: 0.75rem 0;
            margin-top: 1rem;

            &:hover {
                background: #000;
                color: #fff;
            }
        }
    }
}
