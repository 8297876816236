@import "../../../../../assets/styles/variables";

.profile-avatar {
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    left: 8px;
    background: #333;
    background-size: cover;
    background-position: 50%;

    .dropdown-box {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 240px;
        top: 47px;
        right: 200px;
        background: #ffffff;
        justify-content: center;
        box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
        border-radius: 4px;

        .dropdown-li {
            white-space: nowrap;
            color: #0c0f11;
            display: block;

            a {
                padding: 10px 20px;
                &:hover {
                    background: $wirebg;
                }
            }
        }
    }
}
