@import "../../../../assets/styles/variables";

.navbar {
    background: white;
    z-index: 980;
    width: 100%;
    height: 65px;
    position: fixed !important;

    &.nav-transparent {
        background: none;
    }

    &.nav-transparent .box-links {
        background: #fff;
    }

    .navbar-content {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin: 0 auto;
        padding: 0;
        height: 100%;
    }

    .navbar-content li {
        height: 100%;
        display: flex;
        align-items: center;
    }

    .logo-pixt {
        position: relative;
        display: flex;
        width: 80px;
        height: 40px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .logo-pixt img {
        width: 69px;
        height: 28px;
    }

    .box-links {
        display: flex;
        position: relative;
        margin-bottom: 0;
        padding-left: 0;
        list-style-type: none;
    }

    li .links {
        display: flex;
        color: #000;
        text-decoration: none;
        white-space: nowrap;
        padding: 10px;
    }

    .box-cart {
        position: relative;
        margin-left: 10px;
        display: flex;
        height: 100%;
        align-items: center;
    }

    .links.user {
        color: #7a8795 !important;
        align-items: center;
    }

    .logout-link:hover {
        cursor: pointer;
    }

    .bag {
        font-size: 25px;
        cursor: pointer;
    }

    .namelink {
        cursor: pointer;
    }
}

@media screen and (min-width: $sm) {
    .navbar .burger-btn {
        display: none !important;
    }
}

@media screen and (max-width: $sm) {
    .navbar .box-links {
        display: none;
        &.open-mobile-menu {
            display: flex;
            background: #fff;
            position: absolute;
            left: 0;
            top: 60px;
            flex-direction: column;
            width: 100%;
            border-radius: 0 0 5px 5px;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                height: 10px;
                width: 100%;
                background: $secondary-color;
                border-radius: 0 0 5px 5px;
            }

            li {
                text-align: center;
                width: 100%;
                margin: auto;

                &:hover,
                &:focus {
                    background: $secondary-color;
                }

                .button.primary {
                    padding-left: 20px;
                    background: none;
                    color: #000;
                }

                .links {
                    padding: 20px;
                    width: 100%;

                    &.user {
                        width: 100%;

                        .dropdown-box {
                            width: 50vw;
                            left: 0;
                            z-index: 1;
                        }
                    }
                }
            }

            .box-cart {
                padding: 10px 0 25px;
                margin: auto;
            }
        }
    }
}


.close-resp, .open-resp{
    cursor: pointer;
}