@import "../../../assets/styles/variables";

@media screen and (max-width: $md) {
  .purchase-cards-container {
    flex-direction: column !important;

    .purchase-card {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}
