.box-mostbox{
    height: 420px;
}
.box-mostbox h2{
    margin-bottom: 28px;
    font-size: 22px;
    position: relative;
    top: 5px;
    font-weight: 700;
}

.box-most-values{
    position: relative;
    background: #f3f5f7;
    height: 360px;
    border-radius: 4px;
    padding: 20px;
}

.number-mostbox{
    position: absolute;
    left: 20px;
    top: 20px;
    font-size: 30px;
    font-weight: 550;
}

.title-mostbox-stat{
    position: absolute;
    top: 60px;
    color: #7A8795;
}

.credits-title-stat{
    position: absolute;
    bottom: 50px;
    font-size: 14px;
    color: #7A8795;
}
.credits-value-stat{
    position: absolute;
    bottom: 20px;
    line-height: 35px;
}
