.banderole-items {
    display: flex;
    flex-wrap: nowrap;
    min-width: min-content;

    &.photographers > *:not(:first-child),
    &.big-card > *:not(:first-child) {
        margin-left: 2rem;
    }

    &.exhibitions > *:not(:first-child),
    &.images > *:not(:first-child) {
        margin-left: 0.5rem;
    }

    &.moodboards-list {
        display: flex;
        flex-wrap: wrap;
        .box-banderole-card {
            margin: 0 0.5rem;
        }
    }
}
