@import "../../../assets/styles/variables";

.section-bio-container {
  .section-bio {
    max-width: 60%;

    .typo-size-bio {
      font-size: 30px;
    }
  }
}

@media screen and (max-width: $md) {
  .section-bio-container .section-bio .typo-size-bio {
    font-size: 22px;
  }
}

@media screen and (max-width: $sm) {
  .section-bio-container .section-bio {
    max-width: 100%;
  }
}
