.section-about-title{
    display: flex;
    align-items: center;
    width: 100%;
    height: 447px;
    display: flex;
    background:white;
    padding: 128px;
    margin-bottom: 10em;
}



.container-about-title{
    width: 100%;
    height: 447px;
    display: flex;
}


.about-title1{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.about-title2{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #F3F5F7;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
}


.box-about-title{
    position: relative;
    width: 560px;
    height: 332px;
    display: flex;
    flex-direction: column;
}

.box-about-top{
    position: relative;
    height: 93px;
    display: flex;
}

.box-about-top h3{
    font-size: 60px;
    align-self: center;
    font-weight: 700;
}

.line-about-title{
    position: relative;
    width: 322px;
    height: 7px;
    background: #0C0F11;
    top: 52px;
    left: 50px;
}

.box-about-bottom{
    height: 100%;
    padding: 20px;
}
.box-about-bottom-p{
    width: 333px;
    height: 230px;
    line-height: 36px;
    font-weight: 550;
    font-size: 18px;
    
}