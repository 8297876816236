@import "../../../../assets/styles/variables";

.grid-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  grid-auto-rows: minmax(300px, min-content);
  grid-auto-flow: dense;

  .grid-item {
    object-fit: cover;
    height: 100%;
    width: 100%;
    background: no-repeat 50%;
    background-size: cover;
    position: relative;

    &:nth-child(7n),
    &:nth-child(11n-5) {
      grid-row: 2 span;
    }

    &:nth-child(20n-13) {
      grid-column: 2 span;
      grid-row: 2 span;
    }

    &:hover {
      .cloudimage-background-content {
        opacity: 1;
      }
    }

    .cloudimage-background-content {
      transition: all 0.25s ease-in-out;
      background: linear-gradient(0deg,
              rgba(0, 0, 0, 0.5),
              rgba(0, 0, 0, 0.5),
              rgba(0, 0, 0, 0.1),
              rgba(0, 0, 0, 0.1),
              rgba(0, 0, 0, 0.1),
              transparent,
              transparent,
              rgba(0, 0, 0, 0.5),
              rgba(0, 0, 0, 0.5),
      );
      background-blend-mode: overlay;
      opacity: 0;
      height: 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      padding: 0.75rem;

      .btns-top {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        color: #fff;

        .pin-container {
          position: absolute;
          left: 0;
        }

        .btns-container {
          display: flex;

          .button {
            margin-left: 0.5rem;
          }
        }
      }

      .btns-bottom {
        display: flex;
        justify-content: space-between;

        .agency-credits {
          padding: 0.5rem;

          p {
            margin: 0;
          }

          .credit-label {
            color: #fff;
          }

          .credit-value {
            color: $secondary-color;
          }

          > *:not(:first-child) {
            margin-top: 0.75rem;
          }
        }

        .btns-container {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        }
      }
    }
  }
}

@media screen and (max-width: $md) {
  .grid-content {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: $sm) {
  .grid-content {
    grid-template-columns: 1fr;

    .grid-item {
      grid-row: 1 span !important;
      grid-column: 1 span !important;
    }
  }
}
