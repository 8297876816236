.section-upload {
    padding-top: 100px;

    .box-list {
        height: 120px;
        width: 303px;
        position: relative;
        font-size: 150px;
        display: flex;
        flex-direction: column;
    }

    .box-liste {
        height: 50px;
        width: 890px;
        color: #0c0f11;
        position: relative;
        font-size: 30px;
        display: flex;
        flex-direction: row;
    }

    .box-cont-head-notch {
        display: flex;
    }

    .box-l {
        position: relative;
        width: 50%;
        display: flex;
        flex-direction: column;
    }

    .entry-point-notch {
        position: relative;
    }

    #file {
        background-color: #f3f5f7;
        width: 100%;
    }

    .entry-point-third-notch {
        height: 60px;
        width: 430px;

        position: relative;
    }

    .entry-point-flexi-notch {
        display: flex;
    }

    .btncl {
        position: absolute;
        display: flex;

        background-color: #0c0f11;
    }

    .line-item {
        position: absolute;
        height: 60px;
        bottom: 100px;
        width: 1000px;
        flex-direction: row;
    }

    .btn-cl-left {
        background-color: #0c0f11;
    }

    .line-item-element-left {
        position: absolute;
        align-items: center;
        height: 60px;
        display: flex;
        bottom: 10px;
        width: 100%;
    }

    .btn-classic-left-head {
        background-color: #7a8795;
    }

    .btn-m-tag {
        border-radius: 16.5px;
        width: 74px;
        background-color: #f3f5f7;
        color: #575f68;
    }

    .btn-tagento {
        background-color: #f3f5f7;
        color: #575f68;
        width: 75px;
        border-radius: 16.5px;
    }

    .line-item-circle-mood-left {
        display: flex;
        position: relative;
    }

    .line-item-circle-mood-left h3 {
        display: flex;
        position: relative;
        top: 15px;
    }

    .btn-other-m {
        background-color: #f3f5f7;
        color: #575f68;
        width: 112px;
        border-radius: 16.5px;
    }

    .box-carrent {
        display: grid;
        grid-template-columns: 600px 900px 600px;
        height: 600px;
        position: relative;
        width: 100%;
        background-color: green;
    }

    .my-mood-left {
        width: 400px;
        height: 60px;
        position: relative;
        display: flex;
    }

    .input-reg {
        display: flex;
        align-self: center;
        position: relative;
        cursor: pointer;
        padding: 20px;
        width: 100%;
    }

    .input-rogerthat-notch {
        background-color: #ffe942;
        border-radius: 7px;
        justify-content: center;
        border: none;
        align-self: center;
    }

    .roger-that-notch {
        position: relative;
        display: flex;
    }

    .results-section-content {
        display: flex;
        justify-content: space-between;
        > * {
            width: 50%;
        }
    }
}
