.card-edits{
    position: relative;
    width: 256px;
    height: 350px;
    background-color: #f3f5f7;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.card-edits h4{
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 16px;
    color: #575F68;
    white-space: nowrap;
}
.card-edits h5{
    position: absolute;
    top: 30px;
    left: 10px;
    font-size: 13px;
    color: #575F68;
    white-space: nowrap;
}

.card-edits h6{
    position: absolute;
    top: 50px;
    left: 10px;
    font-size: 16px;
    color: #0C0F11;
    font-weight: 700;
    white-space: nowrap;
}
