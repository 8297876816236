@import "../../../assets/styles/variables";

.settings-left {
  width: 200px;
  height: 440px;
  color: $fontgrey;
  margin-right: 40px;

  h3 {
    font-size: 24px;
    letter-spacing: 0.5px;
    white-space: nowrap;
    cursor: pointer;
  }

  ul {
    padding-left: 0;
    list-style-type: none;
  }

  li {
    font-size: 18px;
    padding: 10px;
    cursor: pointer;

    &:hover {
      color: #fff;
      background: #000;
    }

    &.is-active {
      color: #fff;
      background: #000;
    }
  }
}

.settings-middle {
  width: 40%;
  height: 600px;
  padding: 0 40px;

  @media screen and (max-width: $sm) {
    padding: 0;
  }
}

.settings-right {
  width: 390px;
  position: absolute;
  top: 70px;
  left: 1000px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $sm) {
    position: absolute;
    top: 400px;
    width: 200px;
    overflow: hidden;
  }
  @media screen and (max-width: $md) {
    left: 20px;
  }

  .box-set {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    .top-box-set {
      background: $wirebg;
    }

    .right-box-content {
      background: $wirebg;

      .bottom-box-set {
        height: 100%;
        padding: 20px;
        line-height: 32px;
        background: #fff;
      }

      .avatar-container {
        width: 100%;
        height: 150px;
        background: red;
      }
    }
  }
}
