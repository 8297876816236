.simple-image.show {
  .cloudimage-background-content {
    background: linear-gradient(0deg,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.2),
            transparent,
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0.5)
    );
    height: 100%;
    opacity: 0;
    transition: all 0.1s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      opacity: 1;
    }
  }
}
