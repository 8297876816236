.sidebar-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 50;
    padding-top: 65px;
    display: flex;
    .left-panel {
        width: 100%;
        height: 100%;
        backdrop-filter: blur(8px);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sidebar-container {
        width: calc(4/9 * 100%);
        right: 0;
        height: 100%;
        padding-top: 2rem;
        padding-bottom: 2rem;
        overflow-y: auto;
        background: #fff;
        .sidebar-content {
            position: relative;
            max-width: 60%;
            margin: auto;
            .sidebar-close-icon {
                position: absolute;
                right: 0;
                cursor: pointer;
                font-size: 1.5rem;
            }
        }
    }
}
