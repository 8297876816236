@import "assets/styles/variables";
@import "assets/styles/fonts";
@import "assets/styles/typo";
@import "assets/styles/buttons";
@import "assets/styles/form";
@import "assets/styles/gl-columns";

body {
  margin: 0;
  font-family: "PhantomSans Regular", BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: unset !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  overflow-x: hidden;
}

#root {
  max-width: 100% !important;
  overflow-x: hidden !important;
}

.boxed-layout {
  width: 100%;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.page-section {
  margin-bottom: 4rem;
  padding-left: 2em;
  padding-right: 2em;
}

.display-none {
  display: none;
  opacity: 0;
  width: 0;
  height: 0;
}

a,
a:hover {
  color: inherit !important;
  text-decoration: none !important;
}

.primary-color {
  color: $primary-color;
}

.primary-background {
  color: $primary-color;
}

.secondary-color {
  color: $secondary-color;
}

.secondary-background {
  background: $secondary-color;
}

.tertiary-color {
  color: $fontgrey;
}

.vertical-content-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.display-flex {
  display: flex;

  &.space-between {
    justify-content: space-between;
  }
}
