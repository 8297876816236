@import "../../../assets/styles/_variables.scss";

.illustation-authent {
  min-height: 100%;
  width: 100%;
  position: relative;
  .color-background {
    width: 85%;
    height: 100%;
    position: absolute;
    z-index: -1;
    background: red;
  }
  .illustation-container {
    position: absolute;
    width: 65%;
    right: 5%;
    height: 75%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-flow: column nowrap;
    .authent-image {
      height: 100%;
      background-blend-mode: multiply;
    }
  }
  .header-container {
    border: 2px solid #333;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-53%) translateY(-50%);
    width: 85%;
    font-size: 5rem;
    padding: 4.5rem 4rem;
    mix-blend-mode: normal;
  }
}

@media screen and (max-width: $sm) {
  .illustation-authent {
    position: absolute;
    z-index: -1;
    .header-container {
      transform: translate(-50%, -180%);
      height: 4%;
      width: 20%;
      left: 55%;
      top: 37%;
      border: none;
      font-size: 1.5rem !important;
    }
    .illustation-container {
      transform: translateY(-400%);
      height: 10%;
      width: 30%;
      z-index: -1.5;
    }
  }
}
