@import "../../../../../assets/styles/variables";

.header-container {
  .details-header {

    h4 {
      position: relative;
      width: max-content;
      z-index: 0;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 0.5rem;
        right: 0;
        bottom: 0;
        background: $secondary-color;
        z-index: -1;
      }
    }
  }

  .btns-gtprep {
    display: flex;

    > div:not(:first-child) {
      margin-left: 1rem;
    }

    svg {
      margin-right: 0.25rem;
    }
  }
}

@media screen and (min-width: $sm) {
  .header-container.gl-col-12 {
    max-width: 100%;
  }
}
