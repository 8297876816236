.input-dropdown {
    position: relative;
    width: 436px;
    height: 40px;
    background: #f3f5f7;
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;

    .dropdown-arr {
        position: absolute;
        right: 10px;
    }
}

.dropdown-box-datas {
    position: absolute;
    width: 436px;
    z-index: 10;
    background: #f3f5f7;
    overflow-x: hidden;
    box-shadow: 0 0 2px #333;

    .datas-input-dropdown {
        position: relative;
        font-size: 16px;
        padding: 10px;
        width: 100%;
        cursor: pointer;

        &:hover {
            background: darken(#f3f5f7, 5);
        }

        &:not(:last-child) {
            border-bottom: 1px solid darken(#f3f5f7, 10);
        }
    }
}
