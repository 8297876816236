@import "../../../assets/styles/variables";

.banderole-nav {
    display: flex;

    .banderole-nav-item {
        display: flex;
        position: relative;
        width: 45px;
        height: 45px;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.left,
        &.right {
            background: $fontgrey;
            color: #fff;

            &.disabled {
                background: $wirebg;
                color: $fontgrey;
            }

            &:not(.disabled):hover {
                background: #000;
                color: $secondary-color;
            }
        }

        &.right {
            margin-left: 4px;
        }

        .nav-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
}
