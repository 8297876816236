@import "../../../../assets/styles/variables";

.upload-result-card {
    position: relative;
    background: $wirebg;
    width: 100%;
    padding: 1rem 2rem;
    @media screen and (max-width: $md) {
        left: 20px;
    }
    
    &:not(:first-child) {
        margin-top: 1rem;
    }
    .agency-data {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .uploaded-visu {
            background-size: cover;
            background-position: 50%;
            height: 100px;
            width: 100px;
            @media screen and (max-width: $sm) {
                position: absolute !important;
                top: 0;
                left: 0;
                height: 100%;
                opacity: 0.5;
            }
        }
        .names-container {
            width: 100%;
            padding: 0 2rem;
        }
        h5, h6 {
            position: relative;
            z-index: 1;
        }
        h5::after {
            content: '';
            width: 100%;
            height: 4px;
            background: $secondary-color;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: -1;
        }
    }
}
