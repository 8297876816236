.section-community{
    width: 100%;
    display: flex;
    height: 726px;
    margin-bottom: 10em;
}

.container-community-left{
    width: 50%;
    display: flex;
    flex-direction: column;
}
.container-community-right{
    width: 50%;
    display: flex;
    flex-direction: column;
}


.box-join-community{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 523px;
    background: #F3F5F7;
}

.join-community{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 656px;
    height: 303px;
    border: 1px solid #9BA5B1;
    top: -40px;
    left: 40px;

}

.join-community h2{
    position: relative;
    font-weight: 700;
    font-size: 60px;
    line-height: 100px;
    right: 100px;
}


.box-btns-community{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 203px;
    padding: 15px;
}

.btn-join-community{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 197px;
    height: 45px;
    border-radius: 4px;
    background: #0C0F11;
    color: white;
    cursor: pointer;
}
.btn-first-community{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 197px;
    height: 45px;
    border-radius: 4px;
    background: #7A8795;
    color: white;
    left: 24px;
    cursor: pointer;
}


.community-grey{
    position: absolute;
    width: 50%;
    height: 523px;
    background: #F3F5F7;
}

.line-community{
    position: relative;
    width: 300px;
    height: 7px;
    top: 180px;
    left: 30px;
    background: #0C0F11;
}


.box-community-paragraph{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    width: 422px;
    height: 383px;
    padding: 20px;
    top: 200px;
    left: 30px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.content-community-paragraph{
    width: 330px;
}
.content-community-paragraph p{
    line-height: 26px;
    color: #0C0F11;
    font-weight: 550;
}