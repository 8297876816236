.box-mcolors-edit {
  display: flex;
  flex-direction: column;

  h3 {
    margin-bottom: 30px;
  }

  .mcolors-datas {
    display: flex;
    margin: 10px 0;

    .cube-mcolors {
      width: 90px;
      height: 90px;
      margin-right: 20px;
    }

    .content-mcolors {
      min-width: 200px;

      .rgb-data, .hex-data{
        position: relative;
        display: flex;
        align-items: center;
        font-size: 14px;
        min-width: 270px;
        height: 40px;
        padding: 10px;

        h3 {
          position: absolute;
          font-size: 14px;
          display: flex;
          top: 20px;
          left: 10px;
          color: #9A9A9A;
        }
        h4 {
          position: absolute;
          font-size: 14px;
          display: flex;
          top: 20px;
          right: 80px;
          color: #0C0F11;
        }
      }
    }
  }
}
