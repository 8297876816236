.search-bar-item.search-exp-term {
  position: relative;
  min-width: 25%;
  border: none;
  left: 5px;
  border-radius: 5rem 0 0 5rem;
  display: flex;
  align-items: center;

  label {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  .search-input {
    height: 100%;
    width: 100%;
    border: none;
  }
}
