@import "../../../assets/styles/variables";

.container-exp-search {
  display: flex;
  width: 100%;
  border-radius: 5rem;
  border: solid 1px #000;
  min-height: 60px;
  position: relative;

  .search-bar-item:not(:first-child),
  .search-exp-submit {
    cursor: pointer;
    border-left: solid 1px #000;
    width: 100%;
    height: 60px;
  }

  .search-custom-dropdown,
  .search-exp-more {
    display: flex;
    align-items: center;
    position: relative;

    .search-input-data {
      color: #9BA5B1;
      margin: 0 0.5rem;
    }

    .search-dropdown {
      position: absolute;
      display: flex;
      flex-direction: column;
      top: 60px;
      width: 100%;
      z-index: 20;
      border: solid 1px darken($wirebg, 20);

      span {
        cursor: pointer;
        text-align: center;
        width: 100%;
        background: $wirebg;
        position: relative;
        color: #0C0F11;
        padding: 0.5rem 0;

        &:not(:last-child) {
          border-bottom: solid 1px darken($wirebg, 20);
        }

        &:hover {
          background: darken($wirebg, 5);
        }
      }
    }
  }

  .search-exp-submit {
    border-radius: 0 5rem 5rem 0;
  }

  .search-exp-more {
    background: $wirebg;
    max-width: 150px;
    padding-left: 1rem;
  }

  .search-caret {
    position: absolute;
    right: 1rem;
    color: #575F68;
  }
}

@media screen and (max-width: $md) {
  .container-exp-search {
    height: auto;
    flex-wrap: wrap;
    border-radius: unset;
    border: none;

    .search-exp-submit,
    .search-bar-item,
    .search-bar-item.search-exp-term {
      border: solid 1px;
      margin-top: 6px;
      min-height: 60px;
      width: 100%;
      left: 0;
      border-radius: 5rem;

      input {
        background: none;
      }

      &.search-exp-more {
        order: +1;
      }
    }
  }
}
