.btn-classic1{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 50px;
    color: #fff;
    background: #7A8795;
    border-radius: 4px;
    font-size: 14px;
    white-space: nowrap;
    cursor: pointer;
}