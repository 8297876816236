.circle-loc{
    display: flex;
    align-items: center;
    justify-content: center;
    width:36px;
    height:36px;
    border-radius: 18px;
    background-color: #f3f5f7;
}


.icon-loc{
    width: 17px;
    height: 17px;
    color: #000;
}