@import "../../../assets/styles/variables";

.day-section {
  position: relative;
  display: flex;
  margin-bottom: 200px;

  .card-day {
    width: 50%;
    align-items: center;
    flex-flow: column nowrap;
    object-fit: cover;
    background: #7a8795;
    background-size: cover;
  }

  .overimage {
    position: relative;
    width: 100%;
    height: 800px;
    z-index: 110;
    background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.5),
                    rgba(0, 0, 0, 0.5),
                    rgba(0, 0, 0, 0.1),
                    rgba(0, 0, 0, 0.1),
                    rgba(0, 0, 0, 0.1),
                    transparent,
                    transparent,
                    rgba(0, 0, 0, 0.5),
                    rgba(0, 0, 0, 0.5)
    );
  }

  .cards-collection {
    display: flex;
    position: relative;
    width: 45%;
    height: 779px;
    left: 3%;
    overflow: hidden;
  }

  .collection-left {
    position: relative;
    width: 341px;
    height: 779px;
  }

  .collection-right {
    position: relative;
    left: 37px;
    width: 341px;
    height: 779px;
  }

  .box-collection1 {
    position: relative;
    background: #f3f5f7;
    height: 482px;
    width: 341px;
    background-size: cover;
  }

  .box-collection2 {
    position: relative;
    background: #f3f5f7;
    height: 257px;
    width: 341px;
    margin-top: 40px;
    background-size: cover;
  }

  .box-collection3 {
    position: relative;
    background: #f3f5f7;
    height: 221px;
    width: 341px;
    background-size: cover;
  }

  .box-collection4 {
    position: relative;

    height: 221px;
    width: 341px;
    margin-top: 40px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .box-collection5 {
    position: relative;
    background: #f3f5f7;
    height: 257px;
    width: 341px;
    margin-top: 40px;
    background-size: cover;
  }

  .circle-plus {
    width: 58px;
    height: 58px;
    cursor: pointer;
  }

  .box-card {
    display: flex;
    flex-direction: column;

    position: relative;
    height: 152px;
    width: 407px;
    top: 552px;
    left: 100px;
    color: #fff;
  }

  .btns-card {
    position: relative;
    display: flex;
    width: 100%;
    height: 36px;
  }

  .btns-card .btn-licence {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 36px;
    background: #000000;
    border-radius: 4px;
    left: 16px;
    margin-right: 16px;
    color: #ffe942;
    font-size: 16px;
    cursor: pointer;
  }

  .btns-card .btn-cube {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background: #000000;
    border-radius: 4px;
    margin-left: 16px;
    color: #ffe942;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
  }
}

@media screen and (max-width: $lg) {
  .day-section {
    flex-direction: column;
    margin-bottom: 0;

    .card-day,
    .cards-collection {
      width: 100%;
      height: auto;
      left: initial;
    }

    .cards-collection {
      .collection-left,
      .collection-right {
        width: 50%;
        height: auto;
        display: flex;
        left: initial;

        > * {
          margin: 0;
          height: 400px;
          max-width: 50%;
        }
      }
      .collection-right {
        flex-wrap: wrap;
        justify-content: flex-end;

        .box-collection4 {
          order: +1;
          max-height: 100px;
          flex-direction: initial;
          align-self: flex-end;
        }
      }
    }
  }
}

@media screen and (max-width: $sm) {
  .day-section .cards-collection {
    flex-direction: column;
    .collection-left,
    .collection-right {
      width: 100%;
      max-width: 100%;

      > * {
        width: 50%;
        height: 200px;
      }
    }
  }
}
