@import "../../../../assets/styles/variables";

.big-card,
.stories-list {
  .box-banderole-card {
    position: relative;
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    width: 263px;
    height: 360px;
    display: flex;
    align-items: center;
    justify-content: center;

    .cloudimage-background {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: 50%;

      .cloudimage-background-content {
        width: 100%;
        height: 100%;

        .big-card-title {
          position: absolute;
          bottom: 1rem;
          left: 1rem;
          color: $fontgrey;
          margin: 0;
        }
      }
    }
  }
}
