@import "../../../assets/styles/variables";

.section-profil-agency {
  padding-top: 100px;
  display: flex;

  .side-container {
    width: 50%;
  }

  .container-left {
    .data-box-container {
      display: flex;

      .data-box {
        background: $wirebg;
        width: 200px;
        padding: 1rem;

        &:not(:first-child) {
          margin-left: 1rem;
        }

        .info-btn {
          display: flex;
          align-self: center;
        }
      }
    }

    .action-container {
      margin-top: 1rem;
      display: flex;

      .button {
        width: max-content;
        min-width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        min-height: 40px;

        &:not(:first-child) {
          margin-left: 0.5rem;
        }
      }

      .drop-downshare {
        position: absolute;
        left: 0;
        top: 40px;
        width: 100%;
        padding: 10px;
        background-color: $wirebg;
        height: 159px;
        box-shadow: rgba($primary-color, 0.24) 0 3px 8px;

        div {
          margin: 10px;

          cursor: pointer;
        }
      }
    }
  }

  .container-right {
    .profil-grey-agency {
      position: relative;
      height: 319px;
      background: $wirebg;
      top: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;
      display: flex;
      align-items: center;

      .logo-agency {
        position: relative;
        width: 120px;
        height: 120px;
        background: $secondary-color;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 30px;
        right: 60px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50%;
      }
    }
  }
}

@media screen and (max-width: $md) {
  .section-profil-agency {
    flex-direction: column;

    .side-container {
      width: 100%;

      &:not(:first-child) {
        margin-top: 1rem;
      }
      .profil-grey-agency {
        width: 90%;
        align-self: flex-end;
      }
    }
  }
}
