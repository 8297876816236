@mixin gl-col($n) {
  .gl-col-#{$n} {
    width: calc(#{$n} / 12 * 100%);
    @if ($n == 3) {
      max-width: 24.5%;
    } @else if ($n == 4) {
      max-width: 32%;
    } @else if ($n == 6) {
      max-width: 48.5%;
    } @else {
      max-width: calc(#{$n} / 12 * 100%);
    }
  }
}

.gl-col {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  [class*="gl-col-"] {
    position: relative;
    margin: 2.5% auto;
  }

  @for $n from 1 through 12 {
    @include gl-col($n);
  }
}

@media only screen and (max-width: #{$md}) {
  .gl-col [class*="gl-col-"]:not(.gl-col-12) {
    width: 48.5%;
    max-width: 48.5%;
  }
  .gl-col .gl-col-12 {
    max-width: 90%;
  }
}

@media only screen and (max-width: #{$sm}) {
  .gl-col [class*="gl-col-"]:not(.gl-col-12),
  .gl-col .gl-col-12 {
    width: 100%;
    max-width: 80%;
  }
}

/*
 * v1.6
 * 2021 © GL
 */
