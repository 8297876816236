@import "../../../assets/styles/variables";

.section-mostall {
  display: flex;
  position: relative;
  justify-content: space-between;

  > div {
    width: 49%;
  }

  .most-liked {
    display: flex;
    flex-direction: column;
  }

  .most-purchased {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 20%;
  }

  .title-most {
    display: flex;
    position: relative;
  }

  .title-most h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
  }

  .line-mostliked {
    position: relative;
    height: 7px;
    width: 300px;
    background: #0C0F11;
    top: 80px;
    left: 25px;
  }

  .card-most {
    background: #F3F5F7;
    width: 100%;
    height: 440px;
  }

  .pin-pixt {
    position: relative;
    top: 12px;
  }
}

@media screen and (max-width: $md) {
  .section-mostall {
    flex-direction: column;

    > div {
      width: 100%;

      &.most-purchased {
        margin-top: 1rem;
      }
    }
  }
}
