@import './variables';

.field-group {
    &:not(:first-child) {
        margin-top: 1rem;
    }

    .in-error {
        background: $in-error !important;
    }

    .error-msg-container {
        position: relative;
        padding: 0 0 1rem;

        .error-msg {
            position: absolute;
            color: $in-error;
        }
    }
}
