.checkout-container-page {
  width: 1550px;
  height: 1000px;
  display: flex;
}
.checkout-left {
  width: 50%;
  height: 50%;
  display: flex;
}
.checkout-right {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #575f68;
}
.petit-carre {
  height: 100px;
  width: 100px;
  right: 60px;
  flex-direction: column;
  top: 150px;
  position: relative;
  color: #575f68;
}
.pricing-this {
  display: flex;
  font-size: 34px;
}
.carre-images-left {
  height: 246px;
  width: 381px;
  top: 300px;
  right: 1130px;
  background-color: #f3f5f7;
  position: absolute;
}
.petit-check-reg {
  display: flex;
  padding: 20px;
  width: 436px;

  position: relative;
}
.checkout-contact {
  position: relative;
  font-size: 36;
  right: 150px;
}
.point-blank {
  position: relative;
  display: flex;
  left: 10px;
}
.btn-checkout-regular {
  background-color: #0c0f11;
  color: #ffffff;
  width: 350px;
  height: 50px;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
}
.checkout-input-that {
  background: #f3f5f7;
  border: 1px solid #cccccc;
  width: 436px;
  height: 40px;
}
.checkout-input-that-one {
  background: #f3f5f7;
  border: 1px solid #cccccc;
  width: 200px;
  height: 40px;
}
.checkout-input-that-deux {
  background: #f3f5f7;
  border: 1px solid #cccccc;
  width: 236px;
  height: 40px;
}
.checkthis-out {
  display: flex;
  flex-direction: row;
}
