.images-details {
    a {
        text-decoration: none;
        color: inherit;

        &:hover {
            text-decoration: none;
            color: inherit;
        }

        .email-link:hover {
            background: #000;
            color: #fff;
        }
    }

    .action-btn {
        text-align: center;
        padding: 0.75rem 0;
        margin-top: 1rem;
    }
}
