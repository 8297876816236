.section-mic1{
    display: flex;
    flex-direction: column;
    padding: 100px;
    min-height: 600px;
    margin-bottom: 200px;
}

.container-mic-top{
    display: flex;
    height: 290px;
    position: relative;
}

.container-mic-top h2{
    font-size: 70px;
    position: relative;
    left: 20px;
    font-weight: 700;
}


.reg-mic{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 620px;
    height: 157px;
    background: #F3F5F7;
    position: relative;
    top: 90px;
    left: 150px;
}

.icon-encrypt1{
    width: 80px;
    height: 80px;
}

.container-mic-middle{
    display: flex;
    height: 230px;
    width: 470px;
    position: relative;
    line-height: 30px;
}

.container-mic-bottom{
    display: flex;
    height: 45px;
    position: relative;
    align-items: center;
    justify-content: center;
    gap: 24px;

}

.btn-mic1{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0C0F11;
    width: 197px;
    height: 45px;
    border-radius: 4px;
    color: #fff;
}

.btn-mic1.second{
    background: #7A8795;
}