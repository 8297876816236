.image-section-certificate {
  &:not(:first-child) {
    margin-top: 5rem;
  }

  .download-container {
    .blockchain-details {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > *:not(:first-child) {
        margin-left: 1rem;
      }
    }
  }

  .image-preview {
    box-shadow: #000 -15px 0 10px -20px;
    height: 100%;
  }
}
