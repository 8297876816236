.box-account-create {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .icon-validate {
        width: 100px;
        height: 100px;
    }
}
