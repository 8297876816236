.more-filter{
    display: flex;
    align-items: center;
}
.more-filter h3 {
    margin-right: 20px;
    position: relative;
    top: 10px;
    color: #0C0F11;
}
.btn-timeframe,
.btn-framing {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 202px;
    height: 32px;
    border-radius: 16.5px;
    border: 1px solid #9BA5B1;
    cursor: pointer;
    margin-left: 1rem;
}

.btn-framing {
    width: 100px;
}

.btn-timeframe .tf-subtitle,
.btn-framing .fr-subtitle{
    position: absolute;
    font-size: 12px;
    background: #fff;
    width: 80px;
    text-align: center;
    left: 0;
    top: -10px;
}

.btn-timeframe .tf-value {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9BA5B1;
    margin-bottom: 0;
}

.btn-timeframe span {
   padding: 0 10px;
   color: #575F68;
   white-space: nowrap;
}

.timeframe-caret,
.framing-caret {
    position: absolute;
    right: 7px;
}
